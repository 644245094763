// Mobile Menu

$('[data-toggle-menu]').on('click', function(){
    $('.site-header-container').toggleClass('is-active');
    $('html, body').toggleClass('no-scroll');
    $(this).toggleClass('is-active');
    $(this).attr('aria-expanded', function (i, attr) {
        return attr == 'true' ? 'false' : 'true'
    });

    // If we are opening the mobile menu and the user profile menu is open, close it
    if( $(this).hasClass('is-active') && $('.mobile-site-header').find('.dropdown-menu').hasClass('is-active')){
        var mobileDropdownWrapper = $('.mobile-site-header');
        var mobileDropdownMenu = $(mobileDropdownWrapper).find('.dropdown-menu');
        var mobileDropdownButton = $(mobileDropdownWrapper).find('.dropdown-button');

        mobileDropdownMenu.removeClass('is-active');
        mobileDropdownButton
            .removeClass('is-active')
            .attr('aria-expanded', function (i, attr) {
                return attr == 'true' ? 'false' : 'true'
            });
    }
});