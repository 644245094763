// Sticky User Bar

$('[data-toggle-dropdown]').on('click', function(){
    var dropdownButton = $(this);
    var dropdownWrapper = $(dropdownButton).parents('.dropdown-wrapper');
    var dropdownMenu = $(dropdownWrapper).find('.dropdown-menu');

    dropdownMenu.toggleClass('is-active');
    dropdownButton
        .toggleClass('is-active')
        .attr('aria-expanded', function (i, attr) {
            return attr == 'true' ? 'false' : 'true'
        });
});

// Close menu if we click on something outside of the menu
$(window).on('click', function(e){
    var stickyUserButton = $('.sticky-user-bar').find('[data-toggle-dropdown]');
    var stickyUserMenu = $('.sticky-user-bar').find('.dropdown-menu');

    if ($('.sticky-user-bar').find(e.target).length == 0 && $(stickyUserButton).hasClass('is-active')){
        stickyUserButton.toggleClass('is-active');
        stickyUserMenu
            .toggleClass('is-active')
            .attr('aria-expanded', function (i, attr) {
                return attr == 'true' ? 'false' : 'true'
            });
    } 
})
