/**
 * Add loading class to Gravity Form buttons when clicked
 */
var handleFormButton = function() {
    $(this).addClass('is-loading');
}

/**
 * Add label/custom radio button to "other" Gravity Form choice radio fields 
 */
$('<label class="gf_other_choice_label"></label>').insertAfter($('input[value="gf_other_choice"]').siblings('input[type="text"]'));

/**
 * Submit login form when hitting enter on login page
 */
$('.gf_login_form').find('form').on('keypress', function(e){
    var enterKeyCode = 13;
    if(e.which == enterKeyCode){
        $(this).find('#gform_submit_button_0').click();
    }
})

/**
 * Check for any custom submit buttons and set them up to pass on their clicks to our forms submit button 
 */
setupCustomFormSubmitButtons();

function setupCustomFormSubmitButtons(){
    var customSubmitButtons = $('[data-submit-form]');

    customSubmitButtons.each(function(i, el){
        var formID = $(el).data('form-id');
        var formSubmitButton = $(`#gform_wrapper_${formID}`).find('button[type="submit"]');

        $(el).on('click', function(event){
            $(formSubmitButton).click();
        });
    });
}

/**
 * Check for any custom save buttons and set them up to pass on their clicks to our forms save button 
 */
setupCustomFormSaveButtons();

function setupCustomFormSaveButtons(){
    var customSaveButtons = $('[data-save-form]');

    customSaveButtons.each(function(i, el){
        var formID = $(el).data('form-id');
        var formSaveButton = $(`#gform_wrapper_${formID}`).find('.gform_save_link');
        var formSubmitButton = $(`#gform_wrapper_${formID}`).find(`#gform_submit_button_${formID}`);

        if(formSaveButton.length > 0){
            setTimeout(()=>{
                $(el).removeClass('is-hidden');
            }, 500);

            $(el).on('click', function(event){
                $(formSaveButton).click();
                $(el).addClass('is-hidden');
            });
        }

        // Hides save button after form is submitted
        if(formSubmitButton.length > 0){
            $(document).on('gform_confirmation_loaded', function(event, formId){
                if(formId == formID){
                    $(el).addClass('is-hidden');
                }
            });
        }
    });
}