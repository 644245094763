$('[data-copy-participant-emails]').on('click', function(){
    var participantEmails = [];

    // Get all our emails and add them to our array
    $(this).parents('.section--training_participants').find('[data-email]').each(function(i, el){
        return participantEmails.push(el.innerText);
    });

    // Put our emails into a comma separated string
    var stringOfEmails = participantEmails.join(';')

    // Copy text to clipboard
    copyTextToClipboard(stringOfEmails)
    // Update the button state
    updateCopyButton(this);
});

function updateCopyButton(button){
    var defaultText = $(button).text();
    var activeText = $(button).data('active-text');

    $(button).text(activeText)
    $(button).attr('disabled', true);

    setTimeout(() => {
        $(button).text(defaultText);
        $(button).attr('disabled', false);
    }, 1000)
}