const player = new Plyr('#player');
const customThumbnail = $('#player').data('poster');
let hasPlayed = false;

// Update custom thumbnail
// https://github.com/sampotts/plyr/issues/294#issuecomment-389718082
if(customThumbnail){
    setTimeout(() => {
        player.poster = customThumbnail;

        // Show video player after custom thumbnail is loaded
        setTimeout(() => {
            $('.training-video').addClass('is-loaded');
        }, 4000);
    }, 250);
}

// On video play event
player.on('play', event => {
    if(hasPlayed) return false;

    hasPlayed = true;
    $.post(window.location.pathname
        , {watched: true}, function(result){
        // console.log(result);
    });
})